import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  newNotifications: [],
  publicFields: null,
  accessFolders: false,
};

const commonSlice = createSlice({
  name: 'commonSlice',
  initialState,
  //   reducer needs a map
  reducers: {
    saveNewNotification(state, action) {
      state.newNotifications = action.payload;
    },
    savePublicFields(state, action) {
      state.publicFields = action.payload;
    },
    setAccessFolders(state, action) {
      state.accessFolders = action.payload;
    },
  },
});

export const { saveNewNotification, savePublicFields, setAccessFolders } =
  commonSlice.actions;

export default commonSlice.reducer;
