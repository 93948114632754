import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import React, { Suspense, lazy, useState } from 'react';
import 'react-modern-drawer/dist/index.css';
import 'react-quill/dist/quill.snow.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import 'swiper/css';
import 'swiper/css/pagination';
import { Loader } from './Component/Loader';
import './assets/Styles/style.css';
import './assets/Styles/table.css';
import {
  saveNewNotification,
  savePublicFields,
} from './store/common/commonSlice';
import { useDispatch, useSelector } from 'react-redux';
import { BaseURL, apiUrl } from './config/apiUrl';
import { Get } from './Axios/AxiosFunctions';
import { updateUser } from './store/auth/authSlice';
import { useEffect } from 'react';
import { useRef } from 'react';
import { io } from 'socket.io-client';
import ProtectedRouter from './Helper/ProtectedRoute';
import BeforeLoginRoute from './Helper/BeforeLoginRoute';

const ReceiptDetails = lazy(() =>
  import('./pages/Folders/Receipts/ReceiptDetails')
);
const FolderPropertyDetails = lazy(() =>
  import('./pages/Folders/FolderProperties/FolderPropertyDetails')
);
const CustomFolder = lazy(() => import('./pages/CustomFolder'));
const AddEditShoppingList = lazy(() => import('./pages/AddEditShoppingList'));
const ShoppingListDetails = lazy(() => import('./pages/ShoppingListDetails'));
const Notifications = lazy(() => import('./pages/Notifications'));
const Login = lazy(() => import('./pages/Login'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const GetStarted = lazy(() => import('./pages/GetStarted'));
const Assignment = lazy(() => import('./pages/Assignment'));
const AddEditAssignment = lazy(() => import('./pages/AddEditAssignment'));
const Accounts = lazy(() => import('./pages/Accounts'));
const CreateEmployee = lazy(() => import('./pages/CreateEmployee'));
const ViewEmployee = lazy(() => import('./pages/ViewEmployee'));
const Properties = lazy(() => import('./pages/Properties'));
const AddEditProperty = lazy(() => import('./pages/AddEditProperty'));
const Timesheet = lazy(() => import('./pages/Timesheet'));
const Checklist = lazy(() => import('./pages/Checklist'));
const AddEditChecklist = lazy(() => import('./pages/AddEditChecklist'));
const ChecklistDetails = lazy(() => import('./pages/ChecklistDetails'));
const ViewChecklist = lazy(() => import('./pages/ViewChecklist'));
const NotFound = lazy(() => import('./pages/NotFound'));
const AllFolders = lazy(() => import('./pages/Folders/AllFolders'));
const FolderProperties = lazy(() => import('./pages/Folders/FolderProperties'));
const Receipts = lazy(() => import('./pages/Folders/Receipts'));
const Inventory = lazy(() => import('./pages/Inventory'));
const ViewInventory = lazy(() => import('./pages/ViewInventory'));
const CreateInventory = lazy(() => import('./pages/CreateInventory'));
const UpdateEmployee = lazy(() => import('./pages/UpdateEmployee'));
const Calender = lazy(() => import('./pages/Calender'));
const ViewProperties = lazy(() => import('./pages/ViewProperties'));
const AssignmentDetails = lazy(() => import('./pages/AssignmentDetails'));
const Settings = lazy(() => import('./pages/Settings'));
const UpdatePassword = lazy(() => import('./pages/UpdatePassword'));

function App() {
  const socket = useRef(null);
  const dispatch = useDispatch();
  const { access_token, isLogin, user } = useSelector(
    (state) => state?.authReducer
  );
  const { newNotifications } = useSelector((state) => state?.commonReducer);

  const [loading, setLoading] = useState(false);

  const getPublicFields = async () => {
    const publicUrl = BaseURL('admin/all/checklist/property/employees');
    setLoading(true);
    const publicRes = await Get(publicUrl, access_token, false, dispatch);
    if (publicRes !== undefined) {
      dispatch(savePublicFields(publicRes?.data?.data));
    }
    setLoading(false);
  };

  // get me
  const getUser = async () => {
    const url = BaseURL(`users/Me`);
    const response = await Get(url, access_token, false, dispatch);
    if (response !== undefined) {
      await dispatch(updateUser(response?.data?.data?.user));
    }
  };
  const getNotificationFromSocket = async () => {
    const responseData = await Get(
      BaseURL('notifications'),
      access_token,
      false,
      dispatch
    );
    if (responseData !== undefined) {
      const unreadNotifications = responseData?.data?.data?.filter(
        (item) => item?.seen == false
      );
      dispatch(saveNewNotification(unreadNotifications));
    }
    socket.current = io(apiUrl, { transports: ['websocket'] });
    socket.current.emit('join', { id: user?._id });
    socket.current.on('new-notification', ({ notification }) => {
      dispatch(saveNewNotification([...newNotifications, notification]));
    });
  };
  useEffect(() => {
    if (isLogin) {
      getUser();
      getPublicFields();
      getNotificationFromSocket();
    }
  }, [isLogin]);

  if (loading) {
    return <Loader className={'vh-100'} />;
  }
  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <Suspense fallback={<Loader className={'vh-100'} />}>
          <Routes>
            <Route
              exact
              path='/login'
              element={<BeforeLoginRoute element={<Login />} />}
            />
            <Route
              exact
              path='/'
              element={<BeforeLoginRoute element={<GetStarted />} />}
            />
            <Route
              exact
              path='/dashboard'
              element={<ProtectedRouter element={<Dashboard />} />}
            />
            <Route
              exact
              path='/employees'
              element={<ProtectedRouter element={<Accounts />} />}
            />
            <Route
              exact
              path='/employees/:slug'
              element={<ProtectedRouter element={<ViewEmployee />} />}
            />

            <Route
              exact
              path='/create-employee'
              element={<ProtectedRouter element={<CreateEmployee />} />}
            />
            <Route
              exact
              path='/assignment'
              element={<ProtectedRouter element={<Assignment />} />}
            />
            <Route
              exact
              path='/add-edit-assignment'
              element={<ProtectedRouter element={<AddEditAssignment />} />}
            />
            <Route
              exact
              path='/assignment/:slug'
              element={<ProtectedRouter element={<AssignmentDetails />} />}
            />
            <Route
              exact
              path='/folders'
              element={<ProtectedRouter element={<AllFolders />} />}
            />
            <Route
              exact
              path='/folders/properties'
              element={<ProtectedRouter element={<FolderProperties />} />}
            />
            <Route
              exact
              path='/folders/properties/:id'
              element={<ProtectedRouter element={<FolderPropertyDetails />} />}
            />
            <Route
              exact
              path='/folders/receipts'
              element={<ProtectedRouter element={<Receipts />} />}
            />
            <Route
              exact
              path='/folders/receipts/:id'
              element={<ProtectedRouter element={<ReceiptDetails />} />}
            />
            {/* folder---------------- */}
            <Route
              exact
              path='/inventory'
              element={<ProtectedRouter element={<Inventory />} />}
            />
            <Route
              exact
              path='/inventory/:id'
              element={<ProtectedRouter element={<ViewInventory />} />}
            />
            <Route
              exact
              path='/create-inventory'
              element={<ProtectedRouter element={<CreateInventory />} />}
            />
            <Route
              exact
              path='/update-employee'
              element={<ProtectedRouter element={<UpdateEmployee />} />}
            />

            <Route
              exact
              path='/properties'
              element={<ProtectedRouter element={<Properties />} />}
            />
            <Route
              exact
              path='/add-edit-property'
              element={<ProtectedRouter element={<AddEditProperty />} />}
            />
            <Route
              exact
              path='/properties/:slug'
              element={<ProtectedRouter element={<ViewProperties />} />}
            />

            <Route
              exact
              path='/timesheet'
              element={<ProtectedRouter element={<Timesheet />} />}
            />
            <Route
              exact
              path='/checklist'
              element={<ProtectedRouter element={<Checklist />} />}
            />
            <Route
              exact
              path='/calendar'
              element={<ProtectedRouter element={<Calender />} />}
            />
            <Route
              exact
              path='/checklist/property/:id'
              element={<ProtectedRouter element={<ChecklistDetails />} />}
            />
            <Route
              exact
              path='/checklist/:slug'
              element={<ProtectedRouter element={<ViewChecklist />} />}
            />
            <Route
              exact
              path='/add-edit-checklist'
              element={<ProtectedRouter element={<AddEditChecklist />} />}
            />
            <Route
              exact
              path='/settings'
              element={<ProtectedRouter element={<Settings />} />}
            />
            <Route
              exact
              path='/update-password'
              element={<ProtectedRouter element={<UpdatePassword />} />}
            />
            <Route
              exact
              path='/add-edit-shoppinglist'
              element={<ProtectedRouter element={<AddEditShoppingList />} />}
            />
            <Route
              exact
              path='/shopping-list/:slug'
              element={<ProtectedRouter element={<ShoppingListDetails />} />}
            />
            <Route
              exact
              path='/notifications'
              element={<ProtectedRouter element={<Notifications />} />}
            />
            <Route
              exact
              path='/folders/custom'
              element={<ProtectedRouter element={<CustomFolder />} />}
            />
            <Route
              path='*'
              element={<NotFound />}
            />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
